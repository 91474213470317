<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-calendar</v-icon>Calendário de
        Atividades
        <v-spacer></v-spacer>
        <v-btn @click="gerarpdf" color="primary" class="white--text">
          PDF
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Dia</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mês</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="secondary"
                :events="events"
                :type="type"
                event-color="accent"
                :now="now"
                :value="now"
                @click:more="viewDay"
                @click:date="viewDay"
                @click:event="abreAtividade"
              >
                <template v-slot:event="{ event }">
                  <div class="d-flex align-center px-1 py-0">
                    <v-icon
                      small
                      :color="
                        event.prioridade === 1
                          ? 'prioridade_baixa'
                          : event.prioridade === 2
                          ? 'prioridade_normal'
                          : event.prioridade === 3
                          ? 'prioridade_urgente'
                          : 'prioridade_emergencia'
                      "
                    >
                      mdi-flag
                    </v-icon>
                    <div class="black--text mx-2">
                      <strong>
                        {{ event.name }} -
                        {{ event.cliente_nome }}
                      </strong>
                    </div>
                  </div>
                </template>
              </v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="teste"
    />
    <ViewAtividadeColaboradorCalendario
      v-if="dialogAtividadeColaborador"
      :dialogAtividadeColaborador.sync="dialogAtividadeColaborador"
      :item="atividade"
      :calendarioAtividade="calendarioAtividade"
    />
  </v-container>
</template>

<script>
import { fetchCalendarioColaborador } from "@/api/geral/calendarios_atividades_colaborador.js";
import { mapState } from "vuex";
import { parseISO, format } from "date-fns";

const pdfGenerator = () =>
  import(
    /* webpackChunkName: "relatorio" */ "./components/pdf/calendarioPdf.js"
  );
export default {
  name: "CalendarioAtividades",
  data() {
    return {
      loading: true,
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
      },
      events: [],
      now: format(new Date(), "yyyy-MM-dd"),
      pdfDialog: false,
      dialogAtividadeColaborador: false,
      atividade: {},
      calendarioAtividade: true,
    };
  },
  components: {
    PdfDialogComp: () => import("@/components/PdfDialogComp.vue"),
    ViewAtividadeColaboradorCalendario: () =>
      import(
        "@/views/colaborador/atividades/components/ViewAtividadeColaboradorCalendario.vue"
      ),
  },

  watch: {},
  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
      colaborador_id: (state) => state.usuario.colaborador_id,
    }),
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    async getCalendarioColaborador() {
      if (this.colaborador_id) {
        this.loading = true;
        return fetchCalendarioColaborador(
          `?colaborador_id=${this.colaborador_id}`
        )
          .then((response) => {
            this.events = response.map((item) => {
              let event = {};
              event.id = item.id;
              event.name = item.titulo;
              event.start = parseISO(`${item.entrega}`);

              event.prioridade = item.prioridade;
              event.cliente_nome = item.cliente_nome;
              return event;
            });
          })
          .finally(() => {});
      }
    },
    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.$refs.calendar).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
    abreAtividade(item) {
      this.dialogAtividadeColaborador = true;
      this.atividade = item.event.id;
    },
  },
  async mounted() {
    await this.getCalendarioColaborador();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-calendar-daily .v-calendar-daily_head-day {
  overflow-y: scroll;
  max-height: 95%;
  min-height: 600px;
  margin-right: 0 !important;
  // margin-bottom: 100px !important;
}

::v-deep .v-calendar-daily__body {
  display: none;
}
</style>
