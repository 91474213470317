var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"accent text-h5 font-weight-bold pa-3"},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-calendar")]),_vm._v("Calendário de Atividades "),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.gerarpdf}},[_vm._v(" PDF ")])],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Dia")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Semana")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Mês")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"secondary","events":_vm.events,"type":_vm.type,"event-color":"accent","now":_vm.now,"value":_vm.now},on:{"click:more":_vm.viewDay,"click:date":_vm.viewDay,"click:event":_vm.abreAtividade},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{staticClass:"d-flex align-center px-1 py-0"},[_c('v-icon',{attrs:{"small":"","color":event.prioridade === 1
                        ? 'prioridade_baixa'
                        : event.prioridade === 2
                        ? 'prioridade_normal'
                        : event.prioridade === 3
                        ? 'prioridade_urgente'
                        : 'prioridade_emergencia'}},[_vm._v(" mdi-flag ")]),_c('div',{staticClass:"black--text mx-2"},[_c('strong',[_vm._v(" "+_vm._s(event.name)+" - "+_vm._s(event.cliente_nome)+" ")])])],1)]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1)],1)],1),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":"teste"},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e(),(_vm.dialogAtividadeColaborador)?_c('ViewAtividadeColaboradorCalendario',{attrs:{"dialogAtividadeColaborador":_vm.dialogAtividadeColaborador,"item":_vm.atividade,"calendarioAtividade":_vm.calendarioAtividade},on:{"update:dialogAtividadeColaborador":function($event){_vm.dialogAtividadeColaborador=$event},"update:dialog-atividade-colaborador":function($event){_vm.dialogAtividadeColaborador=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }